import { render, staticRenderFns } from "./CustomerTrainingsList.vue?vue&type=template&id=610f2983&scoped=true&"
import script from "./CustomerTrainingsList.vue?vue&type=script&lang=js&"
export * from "./CustomerTrainingsList.vue?vue&type=script&lang=js&"
import style0 from "./CustomerTrainingsList.vue?vue&type=style&index=0&id=610f2983&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "610f2983",
  null
  
)

export default component.exports